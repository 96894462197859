<template> 
  <v-card v-if="entity != null" elevation="0" style="border-radius:0px;">

    <!-- TOOLBAR -->
    <v-toolbar max-height="60" color="" elevation="0" >
      
      <v-gravatar height="30" style="border-radius:50%;" v-if="entity.planPaid"
                :default-img="'retro'" class="mr-2" :email="entity.name"/>

      <v-icon class="pr-1 py-0">mdi-account</v-icon>
      <b class="pr-2">Profil du joueur</b>
      
      <v-spacer/>

      <v-btn small outlined fab class="ml-1"
             @click="$root.$emit('closeDialogEntity')" v-if="inDialog">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- CARD -->
    <v-card-text class="px-3">
      <v-row class="mx-0 my-0">

        <v-col cols="12" sm="12" md="12" class="pt-0 px-0">
          
          <v-list class="py-0">
            <v-list-item class="px-0">
                <v-list-item-avatar v-if="!entity.planPaid" height="46" width="46" :class="'mr-3'">
                  <v-gravatar height="40" style="border-radius:50%; margin-bottom:-6px;"
                              :default-img="'retro'" :email="entity.name"/>
                </v-list-item-avatar>

                <v-list-item-avatar class="mt-5"  height="70" width="70" v-else>
                  <img :src="baseUrl() + entity.imgBadgeCoin"/>
                </v-list-item-avatar>
                
                <v-list-item-content>

                  <v-list-item-title class="headline mb-1" style="line-height: 1.3rem;">
                    <b style="text-transform:none;font-size:0.7em!important;text-decoration:none;white-space:normal;display:inline;" 
                       :class="'pa-0'">
                        <!-- <v-icon  style="margin-bottom:3px;margin-right:-5px;margin-left:-4px;">mdi-account</v-icon> -->
                        {{ entity.name }}
                    </b>
                  </v-list-item-title>
                  
                  <v-list-item-subtitle v-show="entity.address!=null ||  entity.city!=null">
                    <v-icon small style="margin-bottom:3px;margin-right:-1px;" v-if="entity.city != ''">mdi-map-marker</v-icon>
                    {{ entity.address }} {{ entity.city }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle v-if="entity.category!=null">
                    <span :class="entity.category.iconFaColor+'--text'">{{ entity.category.name }}</span>
                  </v-list-item-subtitle>

                </v-list-item-content>
                <v-list-item-content>
                  <div class="text-right">
                  <v-btn   fab color="blue lighten-1" class="mr-2 mt-2" title="contacter" 
                          :disabled="!$store.state.auth.isLogin || !$store.state.auth.user.isActive || $store.state.auth.user._id == entity._id || !$store.getters['auth/accountReady']"
                          @click="startConversation(entity._id); $root.$emit('closeDialogEntity')">
                    <v-icon  color="" class="">mdi-message-outline</v-icon>
                  </v-btn>
                  </div>
                </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-card outlined class="pa-3"
                  v-if="entity.currentMsg.length > 0">
            <v-card-text class="px-0 pt-0 pb-0" v-html="nl2br(entity.currentMsg)">
            </v-card-text>
          </v-card>
          <v-card outlined class="pa-3" v-else>
            <v-card-text class="px-0 pt-0 pb-0">
              <v-icon small>mdi-cancel</v-icon> Aucune description
            </v-card-text>
          </v-card>
          <template v-if="$store.state.auth.isLogin">
            <v-divider class="my-5"/>
            <v-btn small class="mr-2" color="orange" style="font-weight:300 !important;"
                  text elevation="0" :to="'/report/user/'+entity._id">
              <v-icon x-small color="orange" class="pr-1">mdi-shield-alert-outline</v-icon> Signaler ce compte
            </v-btn>
          </template>
        </v-col>

      </v-row>

    </v-card-text>

  </v-card>
</template>

<style>
  @import "~leaflet/dist/leaflet.css";
</style>


<script>

//import axios from "axios"

// import { i18n } from 'vue-lang-router';
import core from '../../plugins/core.js'
const config = require('../../config/' + process.env.NODE_ENV)

import L from 'leaflet';
import Gravatar from 'vue-gravatar'

export default {
  name: 'home',
  props: ['entity', 'inDialog'],
  components: {
    'v-gravatar' : Gravatar
  },
  data: () => ({
    showDialogProposal: false,

    zoom:9,
    mapCenter: L.latLng(47.413220, -1.219482),
    url : config.mapTilesUrl,
    attribution:'', //&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    popupContent: "ok",

  }),
  mounted: async function(){
   
  },
  methods: {
    monyToEuro(amount){ return amount * config.monyToEuro },
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      //let maxLength = 150
      // let p = newStr.length > maxLength ? '...' : ''
      // newStr = newStr.substr(0, maxLength) + p
      return newStr
    },
    baseUrl(){ return core.baseUrl() },
    startTransaction(){
      
    },
    startConversation(userId){
      this.$root.$emit('closeDialogEntity')
      let ok = this.$store.dispatch('app/openConv', { userId: userId }) 
      if(ok) this.$store.dispatch('auth/refreshUserData') 
    }
  },
  computed: {
    marker(){ 
      let color = "orange"
      return L.icon({
        iconUrl: '/marker/marker-'+color+'.png',
        shadowUrl: '/marker/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
    },
  },
  watch: {
  }, 
}
</script>
